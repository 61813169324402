import React, { useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import _ from "lodash";

import service from "../../../../../utils/service";
import brand_colors from "../../../../../utils/colors";
import Handle403 from "../../../../../components/Handle403";

const ReactHighcharts = require("react-highcharts");

const CMM_Statistics = ({ series }) => {
  const [chartdata, setChartdate] = useState({ status: "loading", res: [] });
  const newSeriesData = [];
  const wipSeriesData = [];
  const closedSeriesData = [];
  const EscalationSeriesData = []
  const categories = [];

  useEffect(() => {
    trigger_API();
  }, []);

  const trigger_API = async () => {
    let API_URL = "v1/cases/case_count/status_category?type=day";
    let res = await service("get", API_URL);
    if (res.status === 403) {
      setChartdate({
        status: "403",
        res: data,
      });
    }
    let data = _.get(res, "data");
    let meta = _.get(res, "meta");
    if (data && meta) {
      setChartdate({ status: "done", res: data });
    }
  };

  const getConfig = () => {
    _.isArray(chartdata.res) &&
      chartdata.res.forEach((data) => {
        const date = data.date;
        const newCount = data.NEW;
        const wipCount = data.WIP;
        const closedCount = data.CLOSED;
        const EscalationCount = data.ESCALATION
        newSeriesData.push(newCount);
        wipSeriesData.push(wipCount);
        closedSeriesData.push(closedCount);
        EscalationSeriesData.push(EscalationCount)
        categories.push(date);
      });
    let formattedDate = [];

    _.isArray(categories) &&
      categories.forEach((each) => {
        const date = new Date(each);
        formattedDate.push(
          date.toLocaleDateString("en-US", { month: "short", day: "numeric" })
        );
      });
    const config = {
      chart: {
        type: "column",
        height: 250,
      },
      title: {
        text: "",
        align: "left",
      },
      xAxis: {
        categories: formattedDate,
      },
      yAxis: {
        min: 0,
        title: {
          text: "Case Count",
        },
        stackLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
            color: "gray",
            textOutline: "none",
          },
        },
      },
      legend: {
        align: "right",
        x: 0,
        verticalAlign: "top",
        y: 0,
        floating: true,
        borderWidth: 1,
        shadow: false,
      },
      tooltip: {
        headerFormat: "<b>{point.x}</b><br/>",
        pointFormat: "{series.name}: {point.y}<br/>Total: {point.stackTotal}",
      },
      plotOptions: {
        column: {
          stacking: "normal",
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: "New",
          data: newSeriesData,
          color: brand_colors.new,
        },
        {
          name: "WIP",
          data: wipSeriesData,
          color: brand_colors.wip,
        },
        {
          name: "Closed",
          data: closedSeriesData,
          color: brand_colors.closed,
        },
        {
          name: "ESCALATION",
          data: EscalationSeriesData,
          color: brand_colors.escalation,
        },
      ],
    };
    return config;
  };

  const CmmStatistics = () => {
    switch (chartdata.status) {
      case "done":
        return <ReactHighcharts config={getConfig()} />;
      case "403":
        return <Handle403/>
      default:
        return <Skeleton count={5} />;
    }
  };

  return (
    <div className="card h-100 mb-3 p-0">
      <div className="card-body p-1 m-0">
        <div className="card-title p-2 m-0">CMM Statistics</div>
        {CmmStatistics()}
      </div>
    </div>
  );
};

export default CMM_Statistics;
