const brand_colors = {
  green: '#81c784',
  suceess: '#77A236',
  yellow: '#EAC017',
  amber: '#e7aa50',
  red: '#dc6262',
  blue: '#6599E9',
  new: '#BB9471',
  wip: '#6599E9',
  closed: '#598C68',
  escalation: '#90ED7D'
}

export default brand_colors