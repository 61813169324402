import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment-timezone";
import SanitizedHTML from "react-sanitized-html";
import _ from "lodash";
import allPaths from "../../../route/allPaths";
import service from "../../../utils/service";
import Skeleton from "react-loading-skeleton";
import CustomDropDown from "../../CustomDropDown";
import StatusDropdown from "../../StatusDropdown"
import { Modal, ModalBody, ModalHeader, Input, ModalFooter } from "reactstrap";
import Comments from "../../../pages/Case-Management/case-details/Comments"
import { showModal } from '../../../actions/modalActions/modalActions';
const OffCanvasViewCase = () => {
  const history = useHistory();
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [caseDetails, setcaseDetails] = useState(null);
  const dispatch = useDispatch()
  const data = _.get(caseDetails, '[0]', '');
  let caseId = _.get(data, "case_id", "");
  const is_editable = _.get(data, "editable", "");
  let allowChange = _.get(data, "allow_change", false);
  const queryParams = new URLSearchParams(location.search);
  const [isMounted, setIsMounted] = useState(false);
  const offCanvas = queryParams.get("offCanvas");
  const case_id = queryParams.get("case_id");
  const [workflowId, setworkflowId] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [closureNote, setClosureNote] = useState("");
  const [case_status, setCaseStatus] = useState("");
  const [caseTypeId, setcaseTypeId] = useState("");
  const caseStatus = _.get(data, "case_status", "");
  const [case_assignee, setCaseAssignee] = useState("");
  const [Value,setValue] = useState("")
  const isVisible = useSelector((state) => state.my_profile.my_org.status);
  const status = isVisible === "active" ? true : false
  const handleCloseOffcanvas = () => {
    queryParams.delete("offCanvas");
    queryParams.delete("case_id");
    history.push({ search: queryParams.toString() });
  };

  const trigerModal = () => {
    setModalOpen(!modalOpen);
  };
  useEffect(() => {
    if (isMounted) {
      trigerModal();
    } else {
      setIsMounted(true);
    }
  }, [case_status, case_assignee, caseTypeId,Value]);
  const handleClosureNoteChange = (event) => {
    const inputValue = event.target.value;
    setClosureNote(inputValue);
  };

  const getStatusAndAssignee = (statusName, statusId, ticketId) => {
    setCaseStatus(statusId);
    setCaseAssignee(ticketId);
  };

  const getTypeAndAssignee = (Typeid, workflowId) => {
    setcaseTypeId(Typeid);
    setworkflowId(workflowId);
  };

  const trigger_Patch_API = async () => {
    let API_URL = `v1/cases/${caseId}`;
    let post_info = {
      notes: closureNote,
    };
    if (case_status) {
      post_info.case_status = case_status;
    }
    if (case_assignee) {
      post_info.case_status_assigned = case_assignee;
    }
    if (caseTypeId) {
      post_info.type = caseTypeId;
    }

    if (workflowId) {
      post_info.workflow = workflowId;
    }
    const uniqueId = `id-${Date.now()}`;
    setValue(uniqueId)
    setClosureNote("")
    let res = await service("patch", API_URL, { data: post_info });
    let success = _.get(res, "success");
    if (success) {
      call_case_details_API();
    }
  };

  useEffect(() => {
    if (case_id) {
      call_case_details_API();
    }
  }, [case_id]);

  const handleAssigneeSelect = (assigneeId) => {
    setCaseAssignee(assigneeId);
  };

  const call_case_details_API = async () => {
    setLoading(true); // Start loading
    try {
      const API_URL = `v1/cases/${case_id}`;
      const res = await service("get", API_URL);
      const data = _.get(res, "ticket");
      if (data) {
        setcaseDetails([data]);
      }
    } catch (error) {
      console.error("Error fetching case details:", error);
      // Handle error appropriately, e.g., showing an error message to the user
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    setShow(!!offCanvas);
  }, [offCanvas]);

  const navigatePartyCases = (id, openInNewTab = false) => {
    const path = `${allPaths.PARTY_ROOT_ROUTE}/${id}/cases`;
  
    if (openInNewTab) {
      window.open(path, '_blank');
    } else {
      history.push(path);
    }
  };
  

  const navigateToCaseDetails = (id, openInNewTab = false) => {
    const path = `${allPaths.CMM_ROUTE}/${id}`;
    
    if (openInNewTab) {
      window.open(path, '_blank');
    } else {
      history.push(path);
    }
  };
  
  return (
    <>
      {show && (
        <div
          className={`offcanvas offcanvas-end show`}
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header canvas-header-bg">
            <h5 className="offcanvas-title" id="offcanvasRightLabel">
              <span className="text-primary">
                Case: <span onClick={() => navigateToCaseDetails(case_id)} className="cursor-p">{case_id}</span>
                <i
                  className="fa fa-external-link ms-2 text-primary cursor-p"
                  onClick={() => navigateToCaseDetails(case_id,true)}
                ></i>
              </span>
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={handleCloseOffcanvas}
            ></button>
          </div>

          <div className="offcanvas-body">
            {loading ? (
              <Skeleton count={5} />
            ) : (
              caseDetails &&
              caseDetails.map((item) => {
                const case_id = _.get(item, "case_id");
                const subject = _.get(item, "subject");
                const partyName = _.get(item, "partyName");
                const party_id = _.get(item, "party_id");
                const created_at = _.get(item, "created_at", "---");
                const comment = _.get(item, "comments");
                const case_summary = _.get(item, "case_summary");
                return (
                  <div className="mobile-only-scroll" key={case_id}>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Company</div>
                        <div className="text-primary fs-16">
                          <span onClick={() => navigatePartyCases(party_id)} className="cursor-p">{partyName}</span>
                          <i
                            className="fa fa-external-link ms-2 text-primary cursor-p"
                            onClick={() => navigatePartyCases(party_id,true)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Subject</div>
                        <div className="text-muted fs-16">{subject}</div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Description</div>
                        <div className="text-muted fs-16">
                          <SanitizedHTML html={case_summary} />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex p-2 align-items-center">
                      <div>
                      <div className="fw-semibold fs-18 mb-1">Case Status</div>
                      <div>
                        {is_editable ?
                          <StatusDropdown caseId={caseId} getStatusAndAssignee={getStatusAndAssignee} data={data} InformationPage="Off-Canvas"/>
                          :
                          <div
                            className={`fs-14 text-muted`} style={{marginLeft: "01px" }}
                          >
                            {caseStatus&&caseStatus.toUpperCase()}
                          </div>
                        }
                      </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center p-2">
                      {/* <i className="case-user me-2"></i> */}
                      <div>
                      <div className="fw-semibold fs-18 mb-1">Case Assignee</div>
                      <div>
                        {is_editable ?
                          <CustomDropDown caseId={caseId} onAssigneeSelect={handleAssigneeSelect} data={data} type="case_assignee" Text="Assignee to" InformationPage="Off-Canvas"/> :
                          <div
                            className={`fs-14 text-muted`} style={{marginLeft: "01px" }}
                          >
                            {_.get(data, "case_assignee", "")}
                          </div>
                        }
                      </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center p-2">
                      <div>
                      <div className="fw-semibold fs-18 mb-1">Case Type</div>
                      <div>
                        {is_editable && allowChange ? (
                          <CustomDropDown caseId={caseId} getTypeAndAssignee={getTypeAndAssignee} data={data} type="case_type" Text="Case Type" InformationPage="Off-Canvas"/>
                        ) : (
                          <div
                            className={`${_.get(data, "case_type", "")} fs-14 fw-600`}  style={{marginLeft: "01px" }}
                          >
                            {_.get(data, "case_type", "")}
                          </div>
                        )}
                      </div>
                      </div>
                    </div>
                    <div className="d-flex flex-row">
                      <div className="p-2">
                        <div className="fw-semibold fs-18">Creation Date</div>
                        <div className="text-muted fs-16">
                          {moment
                            .utc(created_at, "YYYY-MM-DD HH:mm:ss.SSSSSS")
                            .tz("Asia/Kolkata")
                            .format("DD MMM YYYY, hh:mm a")}
                        </div>
                      </div>
                    </div>
                    
                      <div className="">
                        <div className="p-2">
                          <div className="fw-semibold fs-18">comments</div>
                            <Comments
                              CommentsData={comment}
                              case_id={case_id}
                              party_id={party_id}
                              cb={call_case_details_API}
                              case_status={caseStatus && caseStatus.toLowerCase()}
                              is_editable={is_editable} 
                              Text="Off-Canvas"
                              />
                        </div>
                      </div>
                
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
      {modalOpen && (
        <Modal isOpen={modalOpen}>
          <ModalHeader>Case Action Note</ModalHeader>
          <ModalBody>
            <Input
              type="textarea"
              name="text"
              id="exampleText"
              value={closureNote}
              onChange={handleClosureNoteChange}
            />
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-secondary"
              onClick={() => window.location.reload()}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              disabled={
                closureNote && closureNote.trim().length >= 5 ? false : true
              }
              onClick={() => {
                status ?
                trigger_Patch_API():dispatch(showModal())
              }}
            >
              Update
            </button>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default OffCanvasViewCase;
